import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import { openMessageDialog } from "./Services/EmailDialogBox";
import { Spinner } from "@fluentui/react-components";
import { Row } from "react-bootstrap";
import { SignIn, sendEmail } from "./Services/MicrosoftApi";

import {
  getAllGroups,
  updateGroup,
  createGroup,
  deleteGroup,
  favoriteGroup,
  getSubscriptionDetail,
} from "./Services/GroupApi";
import { v4 as uuidv4 } from "uuid";
import { extractImageSrc } from "./LogimalAI/Utilities/base64ToImage";
import { Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import { Modal, Box } from "@mui/material";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { DialogSurface, DialogBody, Dialog, makeStyles, Button } from "@fluentui/react-components";
import CreateGroup from "./Create Group/CreateGroup";
import LoginButton from "./LoginHandler/LoginButton";
import DeleteGroup from "./Delete Group/DeleteGroup";
import SelectGroup from "./Select Group/SelectGroup";
import AddSignature from "./Add Signature/AddSignature";
import ApplySignature from "./Apply Signature/ApplySignature";
import ChangeSignature from "./Change Signature/ChangeSignature";
import Quill from "quill";
import ImageDrop from "quill-image-drop-and-paste";
import NotificationBar from "./Notification/NotificationBar";
import GroupTo from "./Groups To/GroupTo";
import From from "./From/From";
import Cc from "./Cc/Cc";
import Subject from "./Subject/Subject";
import { useNavigate } from "react-router-dom";
import { getgroups } from "process";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  span: {
    border: "1px solid black",
    width: "59px",
    display: "inline-block",
    height: "29px",
    padding: "3px",
    paddingLeft: "17px",
  },
  message: {
    color: "blue",
    fontWeight: "500",
    fontSize: "17px",
    marginBottom: "0px",
    marginTop: "10px",
  },
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});
// Register the module
Quill.register("modules/imageDrop", ImageDrop);

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [openFileDialog, setOpenFileDialog] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);
  const quillRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState(true);
  const [signature, setSignature] = useState(null);
  const [applySignature, setApplySignature] = useState(false);
  const [GroupId, setGroupId] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [notify, setNotify] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [CcList, setCcList] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [content, setContent] = useState("");
  const [signContent, setSignContent] = useState("");
  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Event handler for file input change
  const onApplySignature = (value) => {
    setApplySignature(value);
  };

  // Event handler for textarea change
  const handleMessageChange = (html) => {
    setSignContent(html);
    console.log(signContent);
  };

  const handleChange = (html) => {
    console.log(html);
    setContent(html);
    console.log(content);
  };

  // Event handler for form submission
  const handleSubmitDialog = (e) => {
    e.preventDefault();
    console.log(signContent);

    setLoader(true);

    extractImageSrc(signContent)
      .then(({ html, files }) => {
        console.log("Updated HTML:", html);
        console.log("Files Array:", files);

        // Access the selected files and the message here
        const formData = new FormData();

        // Assuming `files` is an array, append each file to the formData
        files.forEach((file) => {
          formData.append("file", file);
        });

        fetch(
          `https://www.api.logimail.site/api/User/UploadFile?email=${window.localStorage.getItem(
            "fromEmail"
          )}&text=${encodeURIComponent(html)}`,
          {
            method: "PUT",
            body: formData,
          }
        )
          .then((response) => {
            setLoader(false);
            console.log(response);
            setOpenFileDialog(false);
            getGroups();
          })
          .catch((err) => {
            console.error("Fetch Error:", err);
            setLoader(false);
          });
      })
      .catch((error) => {
        console.error("Extraction Error:", error);
        setLoader(false);
      });
  };

  const [group, setGroup] = useState({
    id: uuidv4(),
    groupName: "",
    emails: "",
    mailsCount: "",
  });

  useEffect(() => {
    //if (window.localStorage.getItem("loggedIn") == "true") {
    getSubscription();
    // getGroups();
    //setIsLoggedIn(true);
    //} else {
    // setIsLoggedIn(false);
    // }
  }, []);

  const getSubscription = () => {
    setLoader(true);
    getSubscriptionDetail().then((res) => {
      console.log(res);
      if (res === null) {
        navigate("/subscriptions");
      } else {
        console.log(res);
        checkSubscriptionValidity(res);
      }
    });
  };
  function checkSubscriptionValidity(res) {
    // Convert the `endDate` string to a Date object
    const subscriptionEndDate = new Date(res.endDate);
    const day = String(subscriptionEndDate.getDate()).padStart(2, "0");
    const month = String(subscriptionEndDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const year = subscriptionEndDate.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;
    // Get the current date and time
    const currentDate = new Date();

    if (currentDate > subscriptionEndDate) {
      console.log("Subscription has expired. Redirecting to subscription page...");
      navigate("/ExpireSubscription");
    } else {
      const timeDifference = subscriptionEndDate - currentDate; // difference in milliseconds
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
      window.localStorage.setItem("daysLeft", daysRemaining);
      window.localStorage.setItem("plan", res.plan);
      window.localStorage.setItem("endDate", formattedDate);

      console.log(`Subscription is still valid. Days remaining: ${daysRemaining}`);
      getGroups();
    }
  }

  const getGroups = () => {
    setLoader(true);
    getAllGroups()
      .then(async (res) => {
        console.log(res);
        setSignature(res.signature);
        if (res.signature != null) {
          setSignContent(res.signature.text);
        }
        setGroups(res.groups);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setLoader(false);
      });
  };
  const styles = useStyles();

  const handleGroupNameChange = (event) => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      groupName: event.target.value,
    }));
  };

  const handleAddEmail = () => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      emails: [...prevGroup.emails, { id: uuidv4(), email: "", isDelete: false }],
    }));
  };

  const handleRemoveEmail = (indexToRemove) => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      emails: prevGroup.emails.map((email, index) => {
        if (index === indexToRemove) {
          return {
            ...email,
            isDelete: true,
          };
        }
        return email;
      }),
    }));
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    console.log("form submitted!", group);
    setLoader(true);

    if (newGroup) {
      createGroup(group)
        .then(async (res) => {
          console.log(res);
          setPopUp(false);
          getGroups();
          setOpenTable(false);
        })
        .catch((error) => {
          console.error("Login failed:", error);
          setLoader(false);
        });
    } else {
      updateGroup(group)
        .then(async (res) => {
          console.log(res);
          setPopUp(false);
          setViewModal(true);
          getGroups();
        })
        .catch((error) => {
          console.error("Login failed:", error);
          setLoader(false);
        });
    }
  };
  const handleEdit = (g) => {
    setNewGroup(false);
    setViewModal(false);
    setGroup(g);
    setPopUp(true);
  };
  const handleLogin = () => {
    SignIn()
      .then(async (res) => {
        console.log(res);

        window.localStorage.setItem("loggedIn", true);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };
  const addNewGroup = () => {
    setNewGroup(true);

    let group = { id: uuidv4(), groupName: "", emails: "" };
    setGroup(group);
    setPopUp(true);
  };
  const handleDelete = (group) => {
    setGroupId(group.id);
    setDeletePopUp(true);
  };
  const onClose = () => {
    setDeletePopUp(false);
    setPopUp(false);
  };
  const onDelete = () => {
    setLoader(true);
    deleteGroup(GroupId)
      .then(async (res) => {
        console.log(res);
        setDeletePopUp(false);
        getGroups();
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setLoader(false);

        // Handle the error, e.g., show a message to the user
      });
  };
  const openMessageBox = () => {
    openMessageDialog()
      .then(async (res) => {
        setContent(res.message);
        setSubject(res.subject);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };
  function replaceParagraphsWithDivs(content) {
    return content.replaceAll(/<p>/g, "<div>").replaceAll(/<\/p>/g, "</div>");
  }
  const onSend = () => {
    setLoader(true);
    let ccList = [];

    if (CcList === "" || CcList === undefined) {
      ccList = [];
    } else {
      if (CcList.includes(";")) {
        ccList = CcList.split(";");
      } else {
        ccList = [CcList];
      }
    }

    let cleanedContent = replaceParagraphsWithDivs(content);
    console.log(cleanedContent);

    sendEmail(selectedGroups, cleanedContent, subject, applySignature, signature, ccList)
      .then(async (res) => {
        console.log(res);
        setContent("");
        setSelectedGroups([]);
        setLoader(false);
        setNotify(true);
        setOpen(false);
        setTimeout(() => {
          setNotify(false);
          window.location.reload();
        }, 2000);

        // Reload the page after successful email send
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setLoader(false);
        window.location.reload();
      });
  };

  const handleSubjectChangeValue = (value) => {
    setSubject(value);
  };

  const selectGroup = () => {
    setOpenTable(false);
    setViewModal(false);
  };

  const cancelGroup = () => {
    setOpenTable(false);
    setViewModal(false);
    setSelectedGroups([]);
  };

  const handleFavoriteChange = (checked, GroupId) => {
    setLoader(true);
    let group = {
      Id: GroupId,
      isFavorite: checked,
    };
    favoriteGroup(group)
      .then(async (res) => {
        console.log(res);
        getGroups();
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setLoader(false);
      });
  };

  const handleCCChangeValue = (value) => {
    setCcList(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();

      const caretPosition = event.target.selectionStart;
      const updatedValue = CcList.substring(0, caretPosition) + ";" + CcList.substring(event.target.selectionEnd);

      setCcList(updatedValue);
      setTimeout(() => {
        event.target.setSelectionRange(caretPosition + 1, caretPosition + 1);
      });
    }
  };
  const handleKeyDownForEmails = (event) => {
    if (event.keyCode === 32) {
      if (quillRef.current) {
        const quillEditor = quillRef.current.getEditor();
        const selection = quillEditor.getSelection();

        if (selection) {
          const cursorPosition = selection.index;
          quillEditor.insertText(cursorPosition, "\n");
          event.preventDefault();
        }
      }
    }
  };
  const handleEmailsChange = (content) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = content;
    const anchorTags = tempContainer.querySelectorAll("a");
    anchorTags.forEach((anchorTag) => {
      const textContent = anchorTag.textContent.trim();
      const paragraphElement = document.createElement("p");
      paragraphElement.textContent = textContent;
      anchorTag.parentNode.replaceChild(paragraphElement, anchorTag);
    });

    let modifiedContent = tempContainer.innerHTML;
    modifiedContent = modifiedContent.replace(/\s+/g, "");
    modifiedContent = modifiedContent.replace(/<p><p>/g, "<p>");
    modifiedContent = modifiedContent.replace(/<\/p><\/p>/g, "</p>");
    tempContainer.innerHTML = modifiedContent;
    const pTags = tempContainer.querySelectorAll("p");
    let nonEmptyPTagCount = 0;
    pTags.forEach((pTag) => {
      const hasContent = pTag.textContent.trim().length > 0;
      if (hasContent) {
        nonEmptyPTagCount++;
      }
    });

    // Return the updated HTML content
    setGroup((prevGroup) => ({
      ...prevGroup,
      emails: tempContainer.innerHTML,
      mailsCount: nonEmptyPTagCount,
    }));
  };

  const navigate = useNavigate();

  return (
    <>
      {/* Notification Bar  */}
      {notify && <NotificationBar />}

      {/* Loader  */}
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p
            style={{
              fontWeight: "bold",
              color: "black",
            }}
          >
            Loading...
          </p>
        </div>
      )}
      {isLoggedIn ? (
        <Container>
          {/* Crearing new Group Modal  */}
          {popUp && (
            <CreateGroup
              group={group}
              newGroup={newGroup}
              onClose={onClose}
              handleGroupNameChange={handleGroupNameChange}
              AddEmail={handleAddEmail}
              onSubmit={handleSubmit}
              EmailChange={handleEmailsChange}
              KeyDownEmails={handleKeyDownForEmails}
            />
          )}
          {/* Deleting existig Group Modal  */}
          {deletePopUp && <DeleteGroup onClose={onClose} onDelete={onDelete} />}
          {/* Header with prifile */}
          <Header />

          <Row>
            <div
              style={{
                overflow: "auto",
              }}
            >
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button appearance="primary" shape="circular" onClick={addNewGroup}>
                  New Group
                </Button>
                <div>
                  <Button appearance="primary" shape="circular" onClick={() => setViewModal(true)}>
                    Select Group
                  </Button>

                  {/* select group popUp  */}
                  <Modal
                    open={viewModal}
                    onClose={(e) => setViewModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 550,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        maxHeight: "90vh",

                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="modal-header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <h1 className="modal-title fs-5">Groups</h1>

                        <button type="button" className="btn-close" onClick={cancelGroup}></button>
                      </div>
                      <input
                        type="text"
                        placeholder="Search Groups"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        className="mt-1 block w-[83%] px-3 py-1 bg-white border border-black rounded-full text-sm shadow-sm placeholder-slate-400 ml-[20px] focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                      />
                      <div
                        className="modal-body"
                        style={{
                          height: "200px",
                          marginTop: "12px",
                          overflowY: "auto",
                        }}
                      >
                        {/* select group table  */}
                        <SelectGroup
                          searchQuery={searchQuery}
                          groups={groups}
                          setSelectedGroups={setSelectedGroups}
                          handleFavoriteChange={handleFavoriteChange}
                          handleEdit={handleEdit}
                          setOpenTable={setOpenTable}
                          handleDelete={handleDelete}
                          selectedGroups={selectedGroups}
                        />
                      </div>

                      <div className="modal-footer">
                        <button className="me-2 btn btn-outline-secondary btn-sm" onClick={cancelGroup}>
                          Cancel
                        </button>
                        <button className=" btn btn-primary  btn-sm" onClick={selectGroup}>
                          Select
                        </button>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </Row>

          <>
            <Row
              style={{
                marginTop: "20px",
              }}
            >
              <div className="mt-2">
                {/* Selected Groups chips  */}
                <GroupTo selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
                {/* CC  */}
                <Cc CcList={CcList} handleKeyDown={handleKeyDown} handleCCChangeValue={handleCCChangeValue} />
                {/* Email that in use to send Emails  */}
                <From />
                {/* Subject ofa Email  */}
                <Subject subject={subject} handleSubjectChangeValue={handleSubjectChangeValue} />

                {/* <>
                  {signature == null ? (
                    <>
                      <AddSignature setOpenFileDialog={setOpenFileDialog} openMessageBox={openMessageBox} />
                    </>
                  ) : (
                    <>
                      <ApplySignature
                        onApplySignature={onApplySignature}
                        applySignature={applySignature}
                        openMessageBox={openMessageBox}
                      />
                      <div>
                        <Button
                          appearance="transparent"
                          size="small"
                          onClick={() => {
                            setOpenFileDialog(true);
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-shuffle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 0 0 7.556 8a9.6 9.6 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 0 0 6.444 8a9.6 9.6 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5"
                              />
                              <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192" />
                            </svg>
                          </span>
                          <span style={{ fontSize: "14px" }}>&nbsp;&nbsp; Change Signature</span>
                        </Button>
                      </div>
                    </>
                  )}
                  <ChangeSignature
                    openFileDialog={openFileDialog}
                    setOpenFileDialog={setOpenFileDialog}
                    handleSubmitDialog={handleSubmitDialog}
                    signature={signature}
                    signContent={signContent}
                    handleMessageChange={handleMessageChange}
                  />
                </> */}
                <Dialog
                  open={open}
                  onOpenChange={(event, data) => {
                    setOpen(data.open);
                  }}
                >
                  <>
                    <DialogSurface>
                      <DialogBody>
                        <span
                          style={{
                            border: "1px solid black",
                            width: "65px",
                            display: "inline-block",
                            height: "22px",
                            borderRadius: "5px",
                            paddingLeft: "22px",
                            marginTop: "6px",
                          }}
                        >
                          Cc
                        </span>
                        <br />
                        <input
                          type="text"
                          className="form-control form-control-sm "
                          aria-describedby="basic-addon1"
                          style={{
                            height: "33px",
                            position: "fixed",
                            width: "64%",
                            right: "22px",
                          }}
                          value={subject}
                          onChange={(e) => handleSubjectChangeValue(e.target.value)}
                        />

                        <Button
                          shape="circular"
                          appearance="primary"
                          className="float-center"
                          style={{
                            paddingLeft: "40px",
                            paddingRight: "40px",
                            paddingTop: "9px",
                            paddingBottom: "9px",
                            marginTop: "50px",
                            marginLeft: "-4px",
                            position: "relative",
                            right: "-56px",
                          }}
                          onClick={onSend}
                        >
                          Send
                        </Button>
                      </DialogBody>
                    </DialogSurface>
                  </>
                </Dialog>
                <div>
                  {signature != null && (
                    <>
                      <br />
                    </>
                  )}

                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={handleChange}
                    style={{
                      height: "250px",
                      marginTop: "5px",
                    }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ color: [] }, { background: [] }],
                        ["link", "image"],
                        ["clean"],
                      ],
                      imageDrop: true,
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "color",
                      "background",
                      "link",
                      "image",
                    ]}
                  />
                </div>
                <br />
                <br />
                <div className="d-flex justify-content-center mt-5">
                  <Button
                    shape="circular"
                    appearance="primary"
                    className="float-center"
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "9px",
                      paddingBottom: "9px",
                      marginTop: "7px",
                    }}
                    onClick={onSend}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Row>
          </>
        </Container>
      ) : (
        //  Login button
        <LoginButton onLogin={handleLogin} />
      )}
    </>
  );
};

export default Home;
