import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";

import {
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Label,
  makeStyles,
  Button,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  span: {
    border: "1px solid black",
    width: "59px",
    display: "inline-block",
    height: "29px",
    padding: "3px",
    paddingLeft: "17px",
  },
  message: {
    color: "blue",
    fontWeight: "500",
    fontSize: "17px",
    marginBottom: "0px",
    marginTop: "10px",
  },
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});

const CreateGroup = ({
  onClose,
  handleGroupNameChange,
  AddEmail,
  onSubmit,
  EmailChange,
  KeyDownEmails,
  newGroup,
  group,
}) => {
  const styles = useStyles();

  const quillRef = useRef(null);

  return (
    <div>
      <DialogSurface>
        <form>
          <DialogBody>
            <Row
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col xs={6}>
                <DialogTitle>Create Group</DialogTitle>
              </Col>
              <Col xs={4}></Col>
              <Col xs={2}>
                <CloseIcon onClick={onClose} />
              </Col>
            </Row>

            <DialogContent className={styles.content}>
              <Label htmlFor={"text-input"}>Group Name:</Label>

              <div className="">
                <input
                  type="text"
                  className="form-control form-control-sm "
                  placeholder="Group Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ width: "260px" }}
                  value={group.groupName}
                  onChange={handleGroupNameChange}
                />
              </div>
              <Label htmlFor={"text-input"}>Group Emails:</Label>
              <div>
                <ReactQuill
                  theme="snow"
                  value={group.emails}
                  onChange={EmailChange}
                  onKeyDown={KeyDownEmails} // Handle key down event
                  ref={quillRef} // Set the ref to access Quill instance
                  style={{
                    height: "180px",
                    marginTop: "5px",
                  }}
                  modules={{
                    toolbar: false,
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                alignItems: "end",
                marginRight: "10px",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Button appearance="primary" className="w-50" onClick={AddEmail}>
                Add Email
              </Button>

              <Button
                appearance="primary"
                className="w-50"
                onClick={onSubmit}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {newGroup ? "Create Group" : "Update Group"}
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </div>
  );
};

export default CreateGroup;
