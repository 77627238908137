import React, { useEffect } from "react";

function SelectPlan() {
  const redirectToSelectPlan = async (accessToken) => {
    const APP_ID = "66eaa838e430c400085922e5";

    try {
      // Make the API call to Nblocks
      const handoverCodeResult = await fetch(`https://auth.nblocks.cloud/handover/code/${APP_ID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accessToken,
        }),
      }).then((res) => res.json());

      if (handoverCodeResult.code) {
        // Redirect to Nblocks Payments
        window.location.replace(
          `https://backendless.nblocks.cloud/subscription-portal/selectPlan?code=${handoverCodeResult.code}`
        );
      }
    } catch (error) {
      console.error("Error fetching handover code:", error);
    }
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("access_token");

    if (!accessToken) {
      console.error("Access token not found");
      return;
    }

    redirectToSelectPlan(accessToken);
  }, [redirectToSelectPlan]);

  return <div>Loading...</div>;
}

export { SelectPlan };
