import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/Main.css";
import usrimg from "../../../assets/icon-128.png";
const Profile = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/Home");
  };
  return (
    <div>
      <div
        className="compose-bg"
        style={{
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <div className="ml-6">
          <h4 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "15px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={goBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left-circle"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                />
              </svg>
            </span>
            <span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "5px" }}>My Profile</span>
          </h4>
        </div>
        <div className="user-info-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={usrimg} alt="User" />
          </div>
          <h1 className="m-0"> {window.localStorage.getItem("displayName")}</h1>
          <address>{window.localStorage.getItem("fromEmail")}</address>
        </div>

        <div className="profile-list-container">
          <ul>
            <li
              className="font-bold"
              style={{ paddingTop: "10px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {window.localStorage.getItem("displayName")}
            </li>
            <hr className="compose-bg" />
            <li style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {window.localStorage.getItem("fromEmail")}
            </li>
            <hr className="compose-bg" />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center",
              }}
            >
              <div>
                <p>Subscription Plan</p>
                <p>Days Left</p>
                <p>End Date</p>
              </div>
              <div>
                <p>{window.localStorage.getItem("plan")}</p>
                <p>{window.localStorage.getItem("daysLeft")}</p>
                <p> {window.localStorage.getItem("endDate")}</p>
              </div>
            </div>

            <hr className="compose-bg" />

            <li
              style={{
                marginBottom: "14px",
              }}
            >
              Refer a friend
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;
