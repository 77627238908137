import React from "react";

const Subject = ({ subject, handleSubjectChangeValue }) => {
  return (
    <div>
      <span
        style={{
          border: "1px solid black",
          width: "64px",
          display: "inline-block",
          height: "22px",
          borderRadius: "5px",
          paddingLeft: "9px",
          marginTop: "7px",
        }}
      >
        Subject
      </span>
      <input
        type="text"
        className="form-control form-control-sm "
        aria-describedby="basic-addon1"
        value={subject}
        onChange={(e) => handleSubjectChangeValue(e.target.value)}
        style={{ width: "205px", display: "inline", marginLeft: "10px" }}
      />
    </div>
  );
};

export default Subject;
