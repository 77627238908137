import React from "react";
import { MessageBar, MessageBarBody } from "@fluentui/react-components";

const NotificationBar = () => {
  return (
    <div>
      <div
        className="d-flex "
        style={{
          position: "fixed",
          zIndex: 23,
          top: "50%",
          left: "20px",
        }}
      >
        <MessageBar intent="success" style={{ width: "280px" }}>
          <MessageBarBody> Email sent successfully...</MessageBarBody>
        </MessageBar>
      </div>
    </div>
  );
};

export default NotificationBar;
