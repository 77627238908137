import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import { makeStyles, Button, Caption1, Text, tokens, Subtitle1 } from "@fluentui/react-components";
const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  card: {
    width: "300px",
    maxWidth: "100%",
    height: "fit-content",
    marginBottom: "15px",
  },

  section: {
    width: "fit-content",
  },

  title: { margin: "0 0 12px" },

  horizontalCardImage: {
    width: "64px",
    height: "64px",
  },

  headerImage: {
    borderRadius: "4px",
    maxWidth: "44px",
    maxHeight: "44px",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: { margin: "0" },
});
export default function PaymentPlans() {
  const styles = useStyles();
  const Pricing = [
    {
      Price: "Free Full Access",
      Duration: "2 weeks",
    },
    {
      Price: "2 Euros/Promotional",
      Duration: "per week",
      URL: "https://buy.stripe.com/test_00g00m4eWcTk1z2cMN",
    },
  ];
  const navigate = useNavigate();
  const gotoPaymentsScreen = (url) => {
    //gotoPayment("https://buy.stripe.com/dR69E4bM70qZ1uU28d", 0);
    navigate("/makepayment", { state: { url: url } });
  };

  const goToHome = () => {
    navigate("/Home");
  };

  return (
    <div>
      <div className=" h-screen flex justify-center">
        <div>
          <div style={{ height: "75px" }} className="ml-6">
            <h2 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "15px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={goToHome}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                  />
                </svg>
              </span>
              <span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "5px" }}>
                Subscription Plans
              </span>
            </h2>
          </div>
          <div className={styles.main}>
            <section className={styles.section}>
              <section className={styles.section}>
                <section className={styles.section}>
                  {Pricing.map((plan, index) => (
                    <Card key={index} className={styles.card} orientation="horizontal">
                      <CardHeader
                        header={<Text weight="semibold">{plan.Price}</Text>}
                        description={<Caption1 className={styles.caption}>{plan.Duration}</Caption1>}
                        action={
                          index > 0 && (
                            <Button appearance="primary" onClick={() => gotoPaymentsScreen(plan.URL)}>
                              Buy
                            </Button>
                          )
                        }
                      />
                    </Card>
                  ))}
                </section>
              </section>
            </section>
          </div>

          <p className="m-4">
            Need more token?Email us at <b>jonas@balber.lt</b> for custom plans.
          </p>
        </div>
      </div>
    </div>
  );
}
