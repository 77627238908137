import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          <div tabIndex={0}>
            <Button className="btn btn-sm" onClick={() => navigate("/Logimail")}>
              LogiMail
            </Button>
          </div>
        </div>

        <div className="flex-none">
          <div className="dropdown dropdown-end">
            <div tabIndex={2} className="avatar cursor-pointer">
              <div className="w-6 rounded-full">
                <img alt="User" src="assets/user.png" />
              </div>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 pt-0 shadow bg-base-100 rounded-box w-52"
            >
              <li
                style={{
                  margin: "5px",
                }}
              >
                {window.localStorage.getItem("displayName")}
              </li>
              <li>
                <Link
                  to="/profile"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/subscriptions"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Subscriptions
                </Link>
              </li>
              <li>
                <a>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
