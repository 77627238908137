var geocoder = new google.maps.Geocoder();
var directionsService = new google.maps.DirectionsService();
export async function generateSummary() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, async function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        let promptText = `
            "Generate summary of given email content and extract the loading/pickup place/address, unloading/delivery place/address, requested material, requested quantity, TEMP REGIME, the Harmonized System (HS) code, HS Code's description from web, total weight, UN code recognition, ADR class search from web of UN code found, Extract this information if these keywords are found in the email content if not then find from email content and return the response in the exact following valid JSON format and use web search for ADR class always:
            {
              "emailSummaryData": {
                "loadingPlaces": [
                  {
                    "loadingPlace": {"address":"20045 Lainate (MI)","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace": {"address":"Almaty, Kazakhstan","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  },
                  {
                    "loadingPlace":{"address":"Second loading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace":  {"address":"Second Unloading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  },
                  {
                    "loadingPlace":{"address":"Third loading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace":  {"address":"Third Unloading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  }
                ],
                "HSCodes": [
                  {
                    "HSCode": "8504409500",
                    "HSCodeDescription": "Static converters"
                  },
                  {
                    "HSCode": "Second HS Code",
                    "HSCodeDescription": "Second HS Code Description"
                  },
                  {
                    "HSCode": "Third HS Code",
                    "HSCodeDescription": "Third HS Code Description"
                  }
                ],
                "Material": "material",
                "Quantity": "quantity",
                "TempRegime": "temp regime",
                "TotalWeight": "50kg",
                "UNCode": "",
                "ADRClass": "ADR Class search from web based on the UN Code found"
              }
            }"
            here is the email content ${asyncResult.value}`;

        let tokenDetails = {
          ClientId: "",
          ClientSecret: "",
          RedirectUrl: "",
          Code: "",
          RefreshToken: "",
          Prompt: promptText,
        };

        try {
          const response = await fetch("https://www.api.logimail.site/api/User/AIHandler", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(tokenDetails),
          });

          if (!response.ok) {
            throw new Error("Failed to fetch AI response");
          }

          const responseObject = await response.json();
          const stringg = responseObject.choices[0].message.content;

          let dataa;
          if (stringg.startsWith("Response:")) {
            const splitString = stringg.split("Response:");
            dataa = JSON.parse(splitString[1]);
          } else {
            dataa = JSON.parse(stringg);
          }

          const coordinatesData = dataa.emailSummaryData.loadingPlaces;
          const promises = coordinatesData.map(async (place) => {
            const result = await calculateDistance(place.loadingPlace.address, place.unloadingPlace.address);
            place.loadingPlace.coordinates.lang = result.originCoordinates.lng;
            place.loadingPlace.coordinates.lat = result.originCoordinates.lat;
            place.unloadingPlace.coordinates.lang = result.destinationCoordinates.lng;
            place.unloadingPlace.coordinates.lat = result.destinationCoordinates.lat;
            place.distance = result.distanceInKm;
          });

          await Promise.all(promises);

          dataa.emailSummaryData.loadingPlaces = coordinatesData;
          const emailSummaryPhrase = formatEmailSummaryPhrase(dataa.emailSummaryData);

          resolve({
            emailSummary: emailSummaryPhrase,
            loadingPlaces: coordinatesData,
            hsCodes: dataa.emailSummaryData.HSCodes,
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(asyncResult.error);
      }
    });
  });
}
export async function generateShortSummary() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, async function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        let promptText = `
            "Generate summary of given email content and extract the loading/pickup place/address, unloading/delivery place/address, Extract this information if these keywords are found in the email content and return the response in the exact following valid JSON format and use web search for ADR class always:
            {
              "emailSummaryData": {
                "loadingPlaces": [
                  {
                    "loadingPlace": {"address":"20045 Lainate (MI)","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace": {"address":"Almaty, Kazakhstan","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  },
                  {
                    "loadingPlace":{"address":"Second loading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace":  {"address":"Second Unloading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  },
                  {
                    "loadingPlace":{"address":"Third loading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "unloadingPlace":  {"address":"Third Unloading place","coordinates":{"lat":"","lang":""},"lat":"","lang":""},
                    "distance":""
                  }
                ],
              }
            }"
            here is the email content ${asyncResult.value}`;

        let tokenDetails = {
          ClientId: "",
          ClientSecret: "",
          RedirectUrl: "",
          Code: "",
          RefreshToken: "",
          Prompt: promptText,
        };

        try {
          const response = await fetch("https://www.api.logimail.site/api/User/AIHandler", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(tokenDetails),
          });

          if (!response.ok) {
            throw new Error("Failed to fetch AI response");
          }

          const responseObject = await response.json();
          const stringg = responseObject.choices[0].message.content;

          let dataa;
          if (stringg.startsWith("Response:")) {
            const splitString = stringg.split("Response:");
            dataa = JSON.parse(splitString[1]);
          } else {
            dataa = JSON.parse(stringg);
          }

          const coordinatesData = dataa.emailSummaryData.loadingPlaces;
          const promises = coordinatesData.map(async (place) => {
            const result = await calculateDistance(place.loadingPlace.address, place.unloadingPlace.address);
            place.loadingPlace.coordinates.lang = result.originCoordinates.lng;
            place.loadingPlace.coordinates.lat = result.originCoordinates.lat;
            place.unloadingPlace.coordinates.lang = result.destinationCoordinates.lng;
            place.unloadingPlace.coordinates.lat = result.destinationCoordinates.lat;
            place.distance = result.distanceInKm;
          });

          await Promise.all(promises);

          dataa.emailSummaryData.loadingPlaces = coordinatesData;
          const emailSummaryPhrase = formatEmailShortSummaryPhrase(dataa.emailSummaryData);

          resolve({
            emailSummary: emailSummaryPhrase,
            loadingPlaces: coordinatesData,
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(asyncResult.error);
      }
    });
  });
}
export async function searchHSCode(hsCode) {
  return new Promise((resolve, reject) => {
    if (hsCode == null || hsCode == undefined || hsCode == "") {
      // No HS code provided, resolve with an empty string
      resolve("");
    } else {
      let promptText = `
          "Give the description Harmonized System (HS) code return the response in the exact following valid JSON format:
          {
            "hsCodeDescription": "description"
          }"
          here is the code ${hsCode}`;

      let tokenDetails = {
        ClientId: "",
        ClientSecret: "",
        RedirectUrl: "",
        Code: "",
        RefreshToken: "",
        Prompt: promptText,
      };

      fetch("https://www.api.logimail.site/api/User/AIHandler", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tokenDetails),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch AI response");
          }
          return response.json();
        })
        .then((responseObject) => {
          let stringg = responseObject.choices[0].message.content;

          if (stringg.startsWith("Response:")) {
            stringg = stringg.split("Response:");
            stringg = stringg[1];
          }

          // Parse the cleaned string into a JSON object
          let codeDescription = JSON.parse(stringg);
          let hsCodeDescription = codeDescription.hsCodeDescription;

          // Resolve the promise with the HS code description
          resolve(hsCodeDescription);
        })
        .catch((error) => {
          console.error("Error generating slides:", error.message);
          reject(error);
        });
    }
  });
}

function formatEmailSummaryPhrase(data) {
  let emailSummaryPhrase = "<table>";

  // Format loading places
  data.loadingPlaces.forEach((place, index) => {
    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Loading Place ${index + 1}:</b></td>
            <td>${place.loadingPlace.address}</td>
        </tr>`;
    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Coordinates:</b></td>
            <td>${place.loadingPlace.coordinates.lat}, ${place.loadingPlace.coordinates.lang} <span class="copy-coordinates" style="cursor:pointer" onClick="window.CopyValue(${place.loadingPlace.coordinates.lat}, ${place.loadingPlace.coordinates.lang})"><i class="ri-file-copy-line"></i></span></td>
        </tr>`;

    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Unloading Place ${index + 1}:</b></td>
            <td>${place.unloadingPlace.address}</td>
        </tr>`;
    emailSummaryPhrase += `<tr> 
            <td><b style="white-space: nowrap;">Coordinates:</b></td>
            <td>${place.unloadingPlace.coordinates.lat}, ${place.unloadingPlace.coordinates.lang} <span class="copy-unloadingPlacecoordinates" style="cursor:pointer" onClick="window.CopyValue(${place.unloadingPlace.coordinates.lat}, ${place.unloadingPlace.coordinates.lang})"><i class="ri-file-copy-line"></i></span></td>
        </tr>`;

    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Distance ${index + 1}:</b></td>
            <td>${place.distance}</td>
        </tr>`;
  });

  // Format HS Codes
  data.HSCodes.forEach((code, index) => {
    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">HS Code ${index + 1}:</b></td>
            <td>${code.HSCode} (${code.HSCodeDescription})</td>
        </tr>`;
  });

  // Add UN Code and ADR Class
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">Material:</b></td>
        <td>${data.Material || "none"}</td>
   </tr>`;
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">Quantity:</b></td>
        <td>${data.Quantity || "none"}</td>
   </tr>`;
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">Temp Regime:</b></td>
        <td>${data.TempRegime || "none"}</td>
   </tr>`;
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">Total Weight:</b></td>
        <td>${data.TotalWeight || "none"}</td>
   </tr>`;
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">UN Code:</b></td>
        <td>${data.UNCode || "none"}</td>
   </tr>`;
  emailSummaryPhrase += `<tr>
        <td><b style="white-space: nowrap;">ADR Class:</b></td>
        <td>${data.ADRClass || "none"}</td>
   </tr>`;

  emailSummaryPhrase += "</table>";

  return emailSummaryPhrase;
}
function formatEmailShortSummaryPhrase(data) {
  let emailSummaryPhrase = "<table>";

  // Format loading places
  data.loadingPlaces.forEach((place, index) => {
    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Loading Place ${index + 1}:</b></td>
            <td>${place.loadingPlace.address}</td>
        </tr>`;
    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Coordinates:</b></td>
            <td>${place.loadingPlace.coordinates.lat}, ${place.loadingPlace.coordinates.lang} <span class="copy-coordinates" style="cursor:pointer" onClick="window.CopyValue(${place.loadingPlace.coordinates.lat}, ${place.loadingPlace.coordinates.lang})"><i class="ri-file-copy-line"></i></span></td>
        </tr>`;

    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Unloading Place ${index + 1}:</b></td>
            <td>${place.unloadingPlace.address}</td>
        </tr>`;
    emailSummaryPhrase += `<tr> 
            <td><b style="white-space: nowrap;">Coordinates:</b></td>
            <td>${place.unloadingPlace.coordinates.lat}, ${place.unloadingPlace.coordinates.lang} <span class="copy-unloadingPlacecoordinates" style="cursor:pointer" onClick="window.CopyValue(${place.unloadingPlace.coordinates.lat}, ${place.unloadingPlace.coordinates.lang})"><i class="ri-file-copy-line"></i></span></td>
        </tr>`;

    emailSummaryPhrase += `<tr>
            <td><b style="white-space: nowrap;">Distance ${index + 1}:</b></td>
            <td>${place.distance}</td>
        </tr>`;
  });

  emailSummaryPhrase += "</table>";

  return emailSummaryPhrase;
}

function copyLoadingplaceCoordinates(lat, lang) {
  var textarea = document.createElement("textarea");
  textarea.value = `${lat}, ${lang}`;
  textarea.style.position = "fixed";
  textarea.style.opacity = 0;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

function copyUnloadingPlaceCoordinates(lat, lang) {
  var textarea = document.createElement("textarea");
  textarea.value = `${lat}, ${lang}`;
  textarea.style.position = "fixed";
  textarea.style.opacity = 0;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}
function calculateDistance(loadingPlaceAddress, unLoadingPlaceAddress) {
  return new Promise(function (resolve, reject) {
    var originAddress = loadingPlaceAddress;
    var destinationAddress = unLoadingPlaceAddress;

    geocodeAddress(originAddress, function (originLocation) {
      geocodeAddress(destinationAddress, function (destinationLocation) {
        var request = {
          origin: originLocation,
          destination: destinationLocation,
          travelMode: "DRIVING",
        };

        directionsService.route(request, function (response, status) {
          if (status === "OK") {
            var distance = response.routes[0].legs[0].distance.text;
            var originCoordinates = {
              lat: originLocation.lat(),
              lng: originLocation.lng(),
            };
            var destinationCoordinates = {
              lat: destinationLocation.lat(),
              lng: destinationLocation.lng(),
            };

            var result = {
              distanceInKm: distance,
              originCoordinates: originCoordinates,
              destinationCoordinates: destinationCoordinates,
            };

            resolve(result);
          } else {
            var errorResult = {
              error: "Directions request failed: " + status,
            };
            reject(errorResult);
          }
        });
      });
    });
  });
}
function geocodeAddress(address, callback) {
  geocoder.geocode({ address: address }, function (results, status) {
    if (status === "OK" && results[0]) {
      var location = results[0].geometry.location;
      callback(location);
    } else {
      console.error("Geocode was not successful for the following reason: " + status);
    }
  });
}
