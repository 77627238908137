import React from "react";

const Cc = ({ CcList, handleKeyDown, handleCCChangeValue }) => {
  return (
    <div>
      <span
        style={{
          border: "1px solid black",
          width: "64px",
          display: "inline-block",
          height: "22px",
          borderRadius: "5px",
          paddingLeft: "22px",
          marginTop: "7px",
        }}
      >
        CC
      </span>
      <input
        type="text"
        className="form-control form-control-sm "
        aria-describedby="basic-addon1"
        value={CcList}
        onKeyDown={handleKeyDown}
        onChange={(e) => handleCCChangeValue(e.target.value)}
        style={{ width: "205px", display: "inline", marginLeft: "10px" }}
      />
    </div>
  );
};

export default Cc;
